import React from "react";
import { Box, Typography } from "@mui/material";
import Header from "../../components/Header";

const Results = () => {
  const resultsLive = false;
  return (
    <Box width={"90%"} height={"90%"} m="1.5rem 2.5rem">
      <Header
        title={"Αποτελέσματα Μελέτης"}
        subtitle={"Ζωντανές στατιστικές από τη μελέτη"}
      />
      <Box width={"95%"} height={"100%"} mt={"20px"}>
        {resultsLive ? (
          <iframe
            width="80%"
            height="100%"
            src="https://lookerstudio.google.com/embed/reporting/44cdc0ab-b3fc-4a4b-b494-ff6a3d106fd4/page/l71XD"
            allowfullscreen
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          ></iframe>
        ) : (
          <Typography variant={"h1"}>Stay Tuned...</Typography>
        )}
      </Box>
    </Box>
  );
};

export default Results;
