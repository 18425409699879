import React from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";

const Home = () => {
  return (
    <Box width={"90%"} height={"90%"} m="1.5rem 2.5rem">
      <Header
        title={"Εισαγωγή Ασθενούς"}
        subtitle={"Συμπλήρωση φόρμας εισαγωγής ασθενούς"}
      />
      <Box width={"100%"} height={"100%"} mt={"20px"}>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLScLD291TEkuxIH2JyWVsUGfFG6qG5mLqUtjij5KATrLUS0xQQ/viewform?embedded=true"
          width="80%"
          height="100%"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Φορτώνει...
        </iframe>
      </Box>
    </Box>
  );
};

export default Home;
